import React from "react";
import "./textCard.styles.scss";


const TextCard = ({
  pageTitle = false,
  headTitle = true,
  align = "",
  color = "",
  titleAttribute = "title",
  title,
  subTitleAttribute,
  subTitle,
  cardText,
  html
}) => {
  return (
    <div className={`card-text ${align} ${color}`.trim()}>
      {pageTitle && <h1 title={titleAttribute}>{title}</h1>}
      {headTitle && <h2 title={titleAttribute}>{title}</h2>}
      {subTitle && <h3 title={subTitleAttribute}>{subTitle}</h3>}
      {cardText && <div className="card-text__text">{cardText}</div>}
      {html && <div dangerouslySetInnerHTML={{ __html: html }} className="card-text__text" />}
    </div>
  );
};

export default TextCard;