import { graphql, useStaticQuery } from "gatsby";
import { PrismicSliceManipulation } from "../utils/PrismicSliceManipulation";

export const useAboutUsData = () => {
  const aboutUsDataRaw = useStaticQuery(rawData).allPrismicPage.nodes[0];

  const aboutUsData = PrismicSliceManipulation(aboutUsDataRaw.data.body);

  const aboutUsHero = aboutUsData[0]; // hero image

  const aboutMe = aboutUsData[1]; //about me

  const aboutStudio = aboutUsData[2]; //about studio

  const aboutTattoos = aboutUsData[3]; //about tattoo

  const metaDescription = aboutUsDataRaw.data.metadescription.text;
  const metaTitle = aboutUsDataRaw.data.metatitle.text;
  const ogImageUrl = aboutUsDataRaw.data.ogmetaimage.url;

  return {
    aboutUsHero,
    aboutMe,
    aboutTattoos,
    aboutStudio,
    metaDescription,
    metaTitle,
    ogImageUrl
  };
};

const rawData = graphql`
  {
    allPrismicPage(filter: { data: { title: { text: { eq: "Über uns" } } } }) {
      nodes {
        id
        data {
          metadescription {
            text
          }
          metatitle {
            text
          }
          title {
            text
          }
          ogmetaimage {
            url
          }
          body {
            ... on PrismicPageDataBodyCallToAction {
              id
              slice_type
              primary {
                title {
                  text
                }
                description {
                  text
                  html
                }
                image {
                  gatsbyImageData
                  alt
                }
              }
            }
            ... on PrismicPageDataBodyImagewithtext {
              id
              slice_type
              primary {
                description {
                  text
                  html
                }
                image {
                  gatsbyImageData
                  alt
                }
                imagetexttitle {
                  text
                }
              }
            }
            ... on PrismicPageDataBodyImagesSlider {
              id
              slice_label
              slice_type
              primary {
                description {
                  html
                }
                title {
                  text
                }
              }
              items {
                description {
                  html
                }
                image {
                  dimensions {
                    height
                    width
                  }
                  alt
                  gatsbyImageData(height: 1000)
                }
              }
            }
          }
        }
      }
    }
  }
`;
