import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TextCard from "../textCard/TextCard";
import "./imageTextCard.styles.scss";

export const ImageTextCard = ({
  img,
  imgTop = true,
  imgLeft: imgFirst,
  imgAlt,
  transparent = true,
  box,
  alignCenter = false,
  pageTitle =false,
  headTitle =false,
  titleAttribute,
  title,
  subTitleAttribute,
  subTitle,
  cardText,
  dangerouslySetInnerHTML
}) => {
  return (
    <div
      className={`img-card-text${imgTop ? " vertical" : ""}${
        transparent ? " transparent" : ""
      }${box ? " box" : ""}`}
    >
      <div
        className={`img-card-text__img__container${imgFirst ? " first" : ""}`}
      >
        <GatsbyImage
          image={getImage(img)}
          alt={imgAlt}
          className="img-card-text__img"
          objectFit="contain"
        />
      </div>
      <TextCard
        pageTitle={pageTitle}
        headTitle={headTitle}
        title={title}
        titleAttribute={titleAttribute}
        subTitle={subTitle}
        subTitleAttribute={subTitleAttribute}
        cardText={cardText}
        alignCenter={alignCenter}
        html={dangerouslySetInnerHTML}
      />
    </div>
  );
};
