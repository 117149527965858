import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./imageSlider.styles.scss";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

function ThumbnailPlugin(mainRef) {
  return (slider) => {
    const observer = new ResizeObserver(function () {
      slider.update();
    });
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      observer.observe(slider.container);
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(next);
      });
    });
    slider.on("destroyed", () => {
      observer.unobserve(slider.container);
    });
  };
}
// needed because slider wont resize when refreshed or rerendered the page --> slide wont show until browser is resized
const ResizePlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.update();
  });

  slider.on("created", () => {
    observer.observe(slider.container);
  });
  slider.on("destroyed", () => {
    observer.unobserve(slider.container);
  });
};

const ResizeAutoSwithPlugin = (slider) => {
  let timeout;
  let mouseOver = false;
  function clearNextTimeout() {
    clearTimeout(timeout);
  }
  function nextTimeout() {
    clearTimeout(timeout);
    if (mouseOver) return;
    timeout = setTimeout(() => {
      slider.next();
    }, 2000);
  }
  const observer = new ResizeObserver(function () {
    slider.update();
  });

  slider.on("created", () => {
    observer.observe(slider.container);
    slider.container.addEventListener("mouseover", () => {
      mouseOver = true;
      clearNextTimeout();
    });
    slider.container.addEventListener("mouseout", () => {
      mouseOver = false;
      nextTimeout();
    });
    nextTimeout();
  });
  slider.on("dragStarted", clearNextTimeout);
  slider.on("animationEnded", nextTimeout);
  slider.on("updated", nextTimeout);
  slider.on("destroyed", () => {
    observer.unobserve(slider.container);
  });
};

const ImageSlider = ({
  title,
  description,
  images,
  withImageDescription = true,
  withThumbnail = true,
  withAutoSwith = false
}) => {
  const [sliderRef, instanceRef, slider] = useKeenSlider(
    {
      initial: 1,
      loop: true,
      align: "center"
    },

    [withAutoSwith ? ResizeAutoSwithPlugin : ResizePlugin]
  );
  const [thumbnailRef] = useKeenSlider(
    {
      loop: true,
      initial: 1,
      slides: {
        perView: 4,
        spacing: 20
      }
      //   breakpoints: {
      //     "(max-width: 700px)": {
      //       slides: {
      //         perView: 2,
      //         spacing: 10
      //       }
      //     }
      //   }
    },

    [ThumbnailPlugin(instanceRef)]
    // [ResizePlugin]
  );

  return (
    <>
      {title && <h2>{title}</h2>}
      <div className="image-slider">
        {description && (
          <div
            className="image-slider--description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        <div>
          <div className="image-slider--slide">
            <div className="image-slider--arrow">
              <IoIosArrowBack
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              />
            </div>
            <div ref={sliderRef} className="keen-slider">
              {images?.map((img, idx) => (
                <div className="keen-slider__slide">
                  <GatsbyImage
                    key={idx}
                    image={getImage(img.image)}
                    alt={img.image.alt}
                    objectFit="cover"
                    className="image-slider--slide__image"
                  />
                  {withImageDescription && (
                    <div
                      className="image-slider--slide__description"
                      dangerouslySetInnerHTML={{ __html: img.description }}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="image-slider--arrow">
              <IoIosArrowForward
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              />
            </div>
          </div>
          {withThumbnail && (
            <div
              ref={thumbnailRef}
              style={{ maxHeight: "30vh" }}
              className="keen-slider thumbnail"
            >
              {images?.map((img, idx) => (
                <GatsbyImage
                  key={idx}
                  image={getImage(img.image)}
                  alt={img.image.alt}
                  objectFit="cover"
                  className="keen-slider__slide"
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
