export function PrismicSliceManipulation(arr) {
  return arr.map((item) => {
    switch (item.slice_type) {
      case "call_to_action":
        return {
          title: item.primary.title.text,
          description: item.primary.description.html,
          image: item.primary.image,
          alt: item.primary.image.alt,
          buttonLabel: item.primary.button_label,
          sliceType: item.slice_type
        };

      case "textbox":
        return {
          title: item.primary.textboxtitle.text,
          description: item.primary.description.html,
          slice_type: item.slice_type
        };

      case "imagewithtext":
        return {
          title: item.primary.imagetexttitle.text,
          description: item.primary.description.html,
          image: item.primary.image,
          alt: item.primary.image.alt,
          slice_type: item.slice_type
        };
      case "images_slider":
        return {
          title: item.primary.title.text,
          description: item.primary.description.html,
          images: item.items.map((i) => {
            return {
              description: i.description.html,
              image: i.image,
              alt: i.image.alt
            };
          }),
          slice_type: item.slice_type
        };
      case "multi_icontext":
        return {
          title: item.primary.title,
          iconTextData: item.items,
          // alt: item.primary.heroimage.alt,
          slice_type: item.slice_type
        };

      case "tablebox":
        return {
          title: item.primary.tabletitle.text,
          tableData: item.items,
          // alt: item.primary.heroimage.alt,
          slice_type: item.slice_type
        };

      case "elementname":
        return {
          title: item.primary.title.text,
          link: item.primary.link.url,
          // alt: item.primary.heroimage.alt,
          slice_type: item.slice_type
        };

      case "textandimages":
        return {
          textandImages: item.items.map((i) => {
            return {
              subtitle: i.subtitle.text,
              text: i.subtext.html,
              image: i.image,
              alt: i.image.alt
            };
          }),
          title: item.primary.title.text,
          description: item.primary.description.html,
          // alt: item.primary.heroimage.alt,
          slice_type: item.slice_type
        };
    }
  });
}
