import React from "react";
import { useAboutUsData } from "../query/aboutUsQuery";
import Hero from "../components/hero/Hero";
import { ImageTextCard } from "../components/imageTextCard/ImageTextCard";
import "../styles/aboutus.scss";
import ImageSlider from "../components/imageSlider/ImageSlider";
import Seo from "../components/seo/Seo";

const AboutUs = () => {
  const {
    aboutUsHero,
    aboutMe,
    aboutStudio,
    metaDescription,
    metaTitle,
    ogImageUrl,
    aboutTattoos
  } = useAboutUsData();

  return (
    <>
      <Seo
        title={metaTitle}
        description={metaDescription}
        ogImageUrl={ogImageUrl}
        ogTitle={metaTitle}
        ogDescription={metaDescription}
      />
      <div class="page aboutus">
        <Hero
          heroData={aboutUsHero}
          withContentBox={true}
          className="whiteFont-imageTransparent"
        />
        <section>
          <ImageTextCard
            headTitle={true}
            title={aboutMe.title}
            dangerouslySetInnerHTML={aboutMe.description}
            img={aboutMe.image}
            imgAlt={aboutMe.alt}
          />

          <ImageSlider
            title={aboutStudio.title}
            description={aboutStudio.description}
            images={aboutStudio.images}
            withImageDescription={false}
          />
        </section>
        <section>
          <ImageSlider
            title={aboutTattoos.title}
            description={aboutTattoos.description}
            images={aboutTattoos.images}
            withImageDescription={false}
          />
        </section>
      </div>
    </>
  );
};

export default AboutUs;
