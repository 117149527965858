import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "../button/Button";

import "./hero.styles.scss";

const Hero = ({
  heroData,
  className,
  withContentBox = false,
  objectFit = "cover",
  objectPosition = "center",
  withButton = false
}) => {
  const classes = "hero " + className;

  const pathToImage = getImage(heroData.image);

  return (
    <div className={classes}>
      <div className="hero__container__image">
        <GatsbyImage
          image={pathToImage}
          alt={heroData.alt}
          placeholder="blurred"
          className="hero__image"
          objectFit={objectFit}
          objectPosition={objectPosition}
        />
      </div>
      {withContentBox && (
        <div className="hero__container__content">
          {heroData.title ? (
            <h1>{heroData.title}</h1>
          ) : (
            <h1>Modernart Tattoo - Dein Tattoostudio</h1>
          )}
          {heroData.description && (
            <span
              className="hero__container__content__html"
              dangerouslySetInnerHTML={{ __html: heroData.description }}
            />
          )}
          {withButton && (
            <Button color="secondary">
              <Link to={heroData.link}>{heroData.text}</Link>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Hero;
